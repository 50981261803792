import vue from 'vue'
import Vuex from 'vuex'
import router, { resetRouter } from "../router";
import createPersistedState from 'vuex-persistedstate'
vue.use(Vuex)

function addNewRoute (menuList) {
  // console.log(menuList)
  let routes = router.options.routes
  // console.log(routes)
  routes.forEach(routeItem => {
    // console.log("123")
    if (routeItem.path == "/Index") {
      menuList.forEach(menu => {
        let childRoute = {
          path: '/' + menu.menuclick,
          name: menu.menuname,
          meta: {
            title: menu.menuname
          },
          component: () => import('../components/' + menu.menucomponent)
        }
        routeItem.children.push(childRoute)
      })
    }
  })

  resetRouter()
  router.addRoutes(routes)
}

export default new Vuex.Store({
  state: {
    menu: [],
    openTab: [],//所有打开的路由
    activeIndex: null, //也可以是默认激活路径；激活状态
  },
  mutations: {
    setMenu (state, menuList) {
      state.menu = menuList

      addNewRoute(menuList)
    },
    setRouter (state, menuList) {

      addNewRoute(menuList)
    },
    
    // 添加tabs
    add_tabs (state, data) {
      this.state.openTab.push(data);
    },
    // 删除tabs
    delete_tabs (state, route) {
      let index = 0;
      for (let option of state.openTab) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      this.state.openTab.splice(index, 1);
    },
    // 设置当前激活的tab
    set_active_index (state, index) {
      this.state.activeIndex = index;
    },
    clear_tabs(state) {
      // 清空选项卡数组
      state.openTab = [];
    },


  },
  actions: {
  },
  modules: {
  },
  getters: {
    getMenu (state) {
      return state.menu
    }
  },
  plugins: [createPersistedState()]

})