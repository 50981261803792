
import VueRouter from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../components/Login'),
    meta: {
      needLogin: false
    }
  },
  {
    path: '/Index',
    name: 'Index',
    component: () => import('../components/Index'),
    children: [
      {
        path: '/Home',
        name: 'Home',
        meta: {
          title: 'Home',
          needLogin: true
        },
        component: () => import('../components/Home')
      },
      {
        path: '/AdsAdvertisingManage',
        name: 'Demand ADS',
        meta: {
          title: 'Demand ADS',
          needLogin: true
        },
        component: () => import('../components/adsModule/AdsAdvertisingManage')
      },
      {
        path: '/MergeReport',
        name: 'Ad Report',
        meta: {
          title: 'Ad Report',
          needLogin: true
        },
        component: () => import('../components/adsModule/MergeReport')
      },
      {
        path: '/DdjTask',
        name: 'DDJ Task',
        meta: {
          title: 'DDJ Task',
          needLogin: true
        },
        component: () => import('../components/adsModule/DdjTask')
      },
      {
        path: '/ChannelAdvertisingAllocation',
        name: 'Mix Task',
        meta: {
          title: 'Mix Task',
          needLogin: true
        },
        component: () => import('../components/adsModule/ChannelAdvertisingAllocation')
      },
      {
        path: '/DdjChannel',
        name: 'Data Source',//DDJ Supply
        meta: {
          title: 'Data Source',
          needLogin: true
        },
        channelName:'',
        component: () => import('../components/adsModule/DdjChannel')
      },
      {
        path: '/ChannelAccountManage',
        name: 'Supply Account',
        meta: {
          title: 'Supply Account',
          needLogin: true
        },
        component: () => import('../components/adsModule/ChannelAccountManage')
      },
      {
        path: '/AdvertiserManage',
        name: 'Demand Account',
        meta: {
          title: 'Demand Account',
          needLogin: true
        },
        component: () => import('../components/adsModule/AdvertiserManage')
      },
      {
        path: '/ConversionReport',
        name: 'Conversion Report',
        meta: {
          title: 'Conversion Report',
          needLogin: true
        },
        component: () => import('../components/adsModule/ConversionReport')
      },
      {
        path: '/Pid',
        name: 'PID',
        meta: {
          title: 'PID',
          needLogin: true
        },
        component: () => import('../components/adsModule/Pid')
      },
     
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


export function resetRouter () {
  router.matcher = new VueRouter({
    mode: 'history',
    routes: []
  }).matcher
}
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router;